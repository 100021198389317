body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.accordian-color 
{
    background-color :#202e38 !important
    /* background-color  : white !important */
    
}
.collapsed
{
  background-color: white !important;
}




.form-row
{
 display : flex !important;
 flex-direction: column !important
}

.display-box
{
  display : inline !important;


}

.two-col {
  overflow: hidden !important;/* Makes this div contain its floats */
}

.two-col .col1,
.two-col .col2 {
  width: 70% !important;
}
/* 
.two-col .col1 {
  float: left !important
} */

.two-col .col2 {
  float: right !important
}

.two-col label {
  display: block !important
}

.form-table
{
  width: 100% !important;
  border-collapse: collapse !important
}

.form-table td{
  padding: 10px !important
}

.form-table label
{
  margin-bottom: 10px !important;
}

.grid-columns-gap
{
  float: left !important;
  width: 20% !important;
  margin-top: 10px !important
}
.grid-row
  {
    margin-bottom:5%!important;
  }
  .textfield-spacing
{
   margin-top: 20px !important ;
}

.box-size
  {
    padding : 2% !important;
  }

  .box-gap
  {
    margin-top:   5px !important
  }

  .text-color
  {
    color : #ffffff !important;
    font-size:18px !important;
    font-weight: bold !important;
    line-height: 32px !important;
  }

  .header-class
  {
    background-color: white;
  }

  .header-class{

    vertical-align: middle !important
  }

  .typography-class   
  {
    display : flex !important;
    justify-content:  center !important;
  }

  .header-color
  {
    background-color: white !important;
  }

  .grid-distance
  {
    margin-bottom: 12px !important;
  }

.mandatory 
{
  color : red !important
}


/* .margin-top 
{
  margin-top: 30px !important
} */